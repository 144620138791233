

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&family=Roboto:wght@100&display=swap');

@layer components {
  .headerHover{
    @apply px-2 flex items-center md:border border-transparent hover:border-white cursor-pointer duration-100;
  }
}

* {
  margin: 0;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

input[type="radio"] {
  display: none;
}

.star {
  cursor: pointer;
  transition: color 200ms;
}

.breadcrumb {
  background-color: #fff !important;
  font-size: 10px;
}

body {
  background-color: #f8f9fa !important;
}

.bg {
  /* background-image: url(img/pink-header-bg.png); */
  background-position: right 0px;
  background-repeat: no-repeat;
  background-size: 65%;
  transform: translate3d(0px, 0px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  min-height: 700px;
}
.center-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 90vh;
}

.AddressText {
  word-wrap: break-word;
}
.footer {
  /* position: fixed; */
  border-top: 1px solid #eaeaea;
  padding: 20px;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #ccc;
  text-align: center;
}

.paginationBttns {
  width: 100%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}

.paginationBttns a {
  padding: 10px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  color: #ccc;
  cursor: pointer;
}
.paginationBttns a:hover {
  background-color: #ddd;
  color: #fff;
}
.paginationActive a {
  background-color: #ddd;
  color: #fff;
}
